import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const iconStyle = {
  padding: '0 1em'
}

const Social = () => {
  return (
    <div>
      <a
        href="https://twitter.com/rehrafay"
        title="Twitter"
        style = {iconStyle}
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        href="https://scholar.google.com/citations?user=-HiN73wAAAAJ&hl=en"
        title="Google Scholar"
        style = {iconStyle}
      >
        <FontAwesomeIcon icon={faGoogle} />
      </a>
      <a
        href="mailto:rehan_jamil@brown.edu"
        title="E-mail"
        style = {iconStyle}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
    </div>
  )
}

export default Social