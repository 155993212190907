import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import style from '../styles/post.module.css'

export default function Hero({image}) {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      markdownRemark(frontmatter: {title: {eq: "About"}}) {
        id
        html
      }
    }
  `)

  return (
    <div className={style.post}>
      <div className={style.postContent}>
        <h1>Rehan Rafay Jamil</h1>
        <Img
          fluid={image.file.childImageSharp.fluid} className={style.coverImage}
        />
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </div>
    </div>
  )
}