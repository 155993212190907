import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Post from '../components/post'
import Navigation from '../components/navigation'
import Hero from '../components/hero'
import Social from '../components/social'

const Index = ({ data, pageContext: { nextPagePath, previousPagePath } }) => {

  return (
    <>
      <SEO />
      <Layout>
        <Hero image={data} />
        <Social />
      </Layout>
    </>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    nextPagePath: PropTypes.string,
    previousPagePath: PropTypes.string,
  }),
}

export const imageQuery = graphql`
  query {
    file(name: {eq: "rehan-profile"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Index
